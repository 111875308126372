<template>
	<div
		class="modal fade"
		id="changePasswordModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="changePasswordLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="changePasswordLabel">
						Mark Multiple Revenues as Paid
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="changePassword">
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label" for="start-date">Start Date</label>
									<v-datepicker
										style="width: 100%"
										v-model="form.startDate"
										placeholder="Start Date"
										></v-datepicker>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label" for="start-date">End Date</label>
									<v-datepicker
										style="width: 100%"
										v-model="form.endDate"
										placeholder="End date"
										></v-datepicker>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="markMultipleRevenueAsPaid"
						:disabled="processing"
						>
						{{ processing ? 'Processing...' : 'Proceed' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#changePasswordModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'
import { extractErrorMessage } from '@/utils/helpers'
import moment from 'moment'
export default {
  props: {
    show: Boolean,
    partnerSid: String,
    earningId: String
  },
  emits: ['close-modal', 'success'],
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        startDate: '',
        endDate: ''
      },
      processing: false,
      errorMessage: ''
    }
  },
  methods: {
    markMultipleRevenueAsPaid () {
      if (!this.form.startDate || !this.form.endDate) {
        return this.$swal({
          icon: 'error',
          title: 'Start and end dates must be provided',
          showCloseButton: true
        })
      }

      this.processing = true

      this.errorMessage = ''
      const sDate =
        moment(this.form.startDate).format('YYYY-MM-DD') +
        ' ' +
        '00:00:00'
      const endDate =
        moment(this.form.endDate).format('YYYY-MM-DD') +
        ' ' +
        '23:59:59'
      this.$axios
        .post('/cost-revenue/v1/earnings/paid', {
          startDate: sDate,
          endDate: endDate,
          partnerId: this.partnerSid,
          earningId: this.earningId
        })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: 'Status Update Successful!',
            text: 'All revenues for the date range selected has been marked as paid'
          }).then(() => {
            this.closeAllModalAfterSuccess()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: extractErrorMessage(error, 'Unable to fetch trip fare')
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    close () {
      this.form.startDate = ''
      this.form.endDate = ''
      this.$emit('close-modal')
    },
    closeAllModalAfterSuccess () {
      this.form.startDate = ''
      this.form.endDate = ''
      this.$emit('success')
    }
  }
}
</script>
