
<template>
	<div>
		<b-modal
			body-class="modalcart"
			:id="modalId"
			@close="closeModal"
			size="md"
			no-close-on-backdrop
			no-close-on-esc
			>
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<div class="py-2">
				<div class="alert alert-info d-flex align-items-center" role="alert">
					<img class="mr-2" src="@/assets/img/caution.svg" alt="" />
					<p class="mb-0">{{ verificationmessage }}</p>
				</div>

				<p class="mb-3">{{ promptText }}</p>

				<p class="mb-2 prompt-message">
					Please type <b>YES</b> to confirm this action.
				</p>
				<div class="form mb-3">
					<div class="form-group">
						<input
							v-model="form.password"
							class="form-control mb-3"
							type="text"
							autocomplete="new-password"
							/>
					</div>
				</div>
			</div>

			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="processingAction || !isValidForm"
						variant="primary"
						class="float-right"
						@click="handleMarkAsPaid()"
						>
						{{ processingAction ? 'Processing' : 'Proceed' }}
					</b-button>
					<b-button
						:disabled="loading"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()"
						>
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'
export default {
  name: 'DeletePromptModal',
  props: {
    show: {
      type: Boolean
    },
    revenueData: {
      type: Object
    },
    modalTitle: {
      type: String
    },
    verificationmessage: {
      type: String
    },
    promptText: {
      type: String
    }
  },
  data () {
    return {
      modalId: 'markAsPaidConfirmationModal',
      processingAction: false,
      form: {
        password: null
      },
      loading: false
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  computed: {
    isValidForm () {
      return !!(!!this.form.password === true && this.form.password.match(/^YES$/))
    }
  },
  methods: {
    handleMarkAsPaid () {
      this.processingAction = true
      this.$axios
        .post('/cost-revenue/v1/earnings/paid', {
          partnerId: this.revenueData.partnerId,
          revenueIds: [this.revenueData.revenueId],
          earningId: this.revenueData.earningId
        })
        .then(() => {
          this.$emit('close')
          this.resetForm()
          this.$swal({
            icon: 'success',
            title: 'Revenue Status Updated!',
            text: 'This revenue has been marked as paid'
          })
          this.$emit('refreshTable')
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: extractErrorMessage(error, 'Unable to fetch trip fare')
          })
        })
        .finally(() => (this.processingAction = false))
    },
    resetForm () {
      this.form.password = null
    },
    openModal () {
      this.$bvModal.show(this.modalId)
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-info {
  $purple-200: #ebebff;
  $purple-800: #2323f0;
  background: $purple-200;
  color: $purple-800;
  border: none;
}

.modal {
  background-color: #002244;
}

.modalHeader {
  color: black;
  text-align: center;
  font-weight: 600;
}
</style>
