<template>
	<div class="p-4">
		<template v-if="loading">
			<div class="d-flex flex-column justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
				<p class="text-center mt-2"><strong>Loading...</strong></p>
			</div>
		</template>
		<template v-else>
			<div>
				<p>
					<router-link to="/partners/payout" class="pay-nav">Payout<img src="@/assets/img/caret-right.svg" alt="" /></router-link>
					{{ partner?.owner.fname }} {{ partner?.owner.lname }}
				</p>
			</div>
			<div class="row d-flex justify-content-between">
				<b-card class="bg-white col-6 rounded p-3 col-12 col-xl-5 mr-2">
					<template v-if="loading">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template v-else>
						<div class="bg-white rounded p-3">
							<div class="border-bottom">
								<h4>Partner Information</h4>
							</div>
							<div>
								<div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pat-head-text">NAME</h5>
									<p class="m-0 pat-sub-text">
										{{ partner?.owner.fname }} {{ partner?.owner.lname }}
									</p>
								</div>
								<div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pat-head-text">COMPANY</h5>
									<p class="m-0 pat-sub-text">{{ partner?.company_name }}</p>
								</div>
                <div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pat-head-text">PARTNER'S EMAIL</h5>
									<p class="m-0 pat-sub-text">{{ partner?.owner?.email || 'N/A' }}</p>
								</div>
                <div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pat-head-text">COMPANY'S EMAIL</h5>
									<p class="m-0 pat-sub-text">{{ partner?.company_email || 'N/A' }}</p>
								</div>
								<div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pat-head-text">SETTLEMENT ACCOUNT</h5>
									<template v-if="loadPayout">
										<div class="d-flex flex-column justify-content-center align-items-center">
											<div class="spinner-border" role="status"></div>
											<p class="text-center mt-2">
												<strong>Loading...</strong>
											</p>
										</div>
									</template>
									<template v-else>
										<div>
											<h5 class="m-0 text-right pat-sub-text">
												{{ partnerEarns?.settlementAccount?.accountNumber }}
											</h5>
											<p class="m-0 pat-head-text">
												{{ partnerEarns?.settlementAccount?.accountName }} -
												{{ partnerEarns?.settlementAccount?.bankName }}
											</p>
										</div>
									</template>
								</div>
								<div class="d-flex justify-content-between align-items-center py-3">
									<h5 class="m-0 pat-head-text">PARTNERS FULL DETAILS</h5>
									<router-link :to="`/partners/${partner?.id}/${partner?.account_sid}/info/`" class="m-0 pat-link">Click to view</router-link>
								</div>
							</div>
						</div>
					</template>
				</b-card>
				<b-card class="bg-white col-5 rounded p-3 col-12 col-xl-5 ml-2">
					<template v-if="loadPayout">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template v-else>
						<div class="bg-white rounded p-3">
							<div class="border-bottom">
								<h4>Payout</h4>
							</div>
							<div>
								<div class="d-flex justify-content-center align-items-center flex-column py-3">
									<h2 class="m-0 pay-amount my-2">
										₦ {{ partnerEarns?.netRevenue }}
									</h2>
									<p class="m-0 pay-head-text">PARTNERS PAYOUT</p>
								</div>
								<div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pay-head-text">Total amount</h5>
									<p class="m-0 pat-sub-text">
										₦ {{ partnerEarns?.totalRevenue }}
									</p>
								</div>
								<div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pay-head-text">Deductions</h5>
									<p class="m-0 text-danger">
										-₦ {{ partnerEarns?.totalDeductions }}
									</p>
								</div>
                <div class="d-flex justify-content-between align-items-center border-bottom py-3">
									<h5 class="m-0 pay-head-text">Status</h5>
									<p class="m-0 font-medium"
                    :class="partnerEarns?.status === 'settled' ?  'text-success' : partnerEarns?.status === 'failed' ? 'text-danger' : 'text-[orange]'"
                  >
										{{ Capitalize(partnerEarns?.status) }}
									</p>
								</div>
								<div class="d-flex justify-content-between align-items-center py-4">
									<div class="tool">
										<h5 class="m-0 pay-head-text">
											Approvals <img src="@/assets/img/icons/question.svg" />
										</h5>
										<span class="tooltext">Two approvals are needed to initiate this payout</span>
									</div>

									<div class="tool flex flex-col gap-[8px]" >
                    <p class="m-0" v-for="item in approver" :key="item.id">{{ item.staffName }}</p>
									</div>
								</div>
							</div>
						</div>
					</template>
				</b-card>
				<b-card v-if="earningDeductions.length > 0" class="bg-white col-5 rounded p-3 col-12 col-xl-5 ml-2">
					<template v-if="loadingDeductions">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template v-else>
						<div class="bg-white rounded p-3">
							<div class="border-bottom">
								<h4>Deductions on earnings</h4>
							</div>
							<div>
              <div class="d-flex justify-content-between align-items-center border-bottom py-3">
                  <h5 class="m-0 pay-head-text col">Date</h5>
                  <h5 class="m-0 pay-head-text col">Deduction</h5>
                  <h5 class="m-0 pay-head-text col">Reason</h5>
              </div>
                <div>
                  <div v-for="(deduction, index) in earningDeductions" v-if="!deduction.tripRevenueId" class="flex border-b">
                      <p class="w-1/3 p-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {{ getTripDate(deduction.createdAt) }}
                      </p>
                      <p class="w-1/3 p-3 overflow-hidden whitespace-nowrap overflow-ellipsis text-red-500">
                          - ₦ {{ deduction.amount }}
                      </p>
                      <p class="w-1/3 p-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {{ deduction.description }}
                      </p>
                  </div>
              </div>
          </div>

						</div>
					</template>
				</b-card>
			</div>

			<div class="d-flex justify-content-end gap-4">
				<button type="button" aria-expanded="false" @click='openResyncModal
				' class="btn btn-lg btn-light text-black mt-4">
					Resync Revenues
				</button>
				<button 	v-if="userHasPermission('partners009')" type="button" aria-expanded="false" @click="markRevenuesAsPaid" class="btn btn-lg btn-light text-black mt-4">
					Mark Multiple Revenues as Paid
				</button>
			</div>
			<div class="card my-4">
				<div class="card-header justify-content-between">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input v-model.trim="search" class="form-control list-search" disabled="true" type="search" placeholder="Search" @keyup.enter.prevent="getPartnerItinerary(true)" />
						<span class="input-group-text border-0">
							<i class="fe fe-search"></i>
						</span>
					</div>
					<div class="col-auto">

						<v-datepicker style="width: 100%" v-model="datePicker" placeholder="Filter by date" range></v-datepicker>
						<DownloadButton :downloading="downloadingReport" label="Download Report" @download="downloadReport()"></DownloadButton>
					</div>
				</div>
				<b-table striped hover selectable responsive show-empty :items="partnerItinerary" :fields="fields" :current-page="currentPage" :busy="fetchPart">
					<template #table-busy>
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(serial)="data">
						{{ data.index + 1 }}
					</template>
					<template #cell(route)="data">
						<route-description :max-width="250" :pickup="data.item.metadata.pickup" :destination="data.item.metadata.dropoff">
						</route-description>
					</template>
					<template #cell(date)="data">
						{{ getTripDate(data.item.tripStartTime) }} <br>
						{{ getTripTime(data.item.tripStartTime) }}
					</template>
					<template #cell(timeOfCreation)="data">
						{{ getTripDate(data.item.createdAt) }}
						<div v-if="data.item.metadata.actor">
							<span id="settled-tag">Marked by: {{ data.item.metadata.actor.fname }}
								{{ data.item.metadata.actor.lname }}</span>
							<!-- </div> -->
						</div>
					</template>
					<template #cell(routeCode)="data">
						{{ data.item.metadata.routeCode }}
					</template>
					<template #cell(amount)="data">
						{{ data.item.partnersRevenue }}
					</template>
					<template #cell(deduction)="data">
						<span class="text-danger">{{ data.item.totalDeductedAmount }}</span>
					</template>
					<template #cell(netincome)="data">
						{{ data.item.finalPartnersRevenue }}
					</template>
					<template #cell(description)="data">
						{{ data.item.description ? data.item.description : 'N/A' }}
					</template>
					<template #cell(isSettled)="data">
						<b-badge variant="success" v-if="data.item.isSettled">settled</b-badge>
						<b-badge variant="warning" v-else>not settled</b-badge>
						<div v-if="data.item.approvedBy">
							<span id="settled-tag">by: {{ data.item.approvedBy.staffName }}</span>
						</div>
					</template>
					<template #cell(action)="data">
						<div>
							<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret no-flip class="m-2">
								<template #button-content>
									<p class="mt-3" style="font-weight: 900">&#x2026;</p>
								</template>
								<b-dropdown-item class="font-weight-bold" href="#" @click="
									markRevenueAsPaid({
										partnerId: data?.item?.partnerId,
										revenueId: data?.item?.id,
									})
								">Mark as Paid
								</b-dropdown-item>
                <b-dropdown-item class="font-weight-bold" href="#" @click="openDeductRevenue(data.item)">Deduct from revenue</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>
				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</template>
		<MarkRevenuesAsPaidModal :partnerSid="partnerSid" :show="showMultipleRevenueModal" :earningId="earningId" @close-modal="closeMultipleRevenueModal()" @success="closeModalAndRefreshView()" />
		<mark-as-paid-confirmation-modal :show="showMarkAsPaidConfirmationModal" @close="showMarkAsPaidConfirmationModal = false" @refreshTable="refreshTable" :revenueData="selectedRevenueData" modalTitle="Are you absolutely sure ?"
			verificationmessage="This action is irreversible and will impact partner revenue!" promptText="If you proceed, the cost of the supply will be deducted from the partner's account and this revenue will be recorded as paid."></mark-as-paid-confirmation-modal>

		<BS_modal :show='isResyncModal' title='Resync Revenue' @close='isResyncModal = false' modalId='isResyncModal'>
			<span v-if='isResyncModalLoading'>Loading...</span>
			<div class="flex flex-col" v-else-if='!isResyncModalLoading && isResyncData.length'>
				<label v-for='vehicle in isResyncData' :key='vehicle.id' :for='vehicle.id' class='bg-white text-slate-700 p-3.5 rounded-md border shadow-md cursor-pointer'>
					<input type="checkbox" :value='vehicle' v-model='selectedResyncVehicles' :id='vehicle.id' class='text-xl mr-3' />
					{{ vehicle.brand }} {{ vehicle.name }} {{ vehicle.registration_number }}
				</label>
			</div>
			<span v-else>
				No vehicles found
			</span>
			<b-button class="mt-3 bg-black" block @click="resyncVechicles">{{ isResyncLoading ? 'Loading...' : 'Resync' }}</b-button>
		</BS_modal>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { Capitalize } from '@/composables/utils'
</script>

<script>
import { debounce } from 'lodash'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import moment from 'moment'
import TableView from '@/mixins/tableview'
import MarkRevenuesAsPaidModal from '@/components/modals/MarkRevenuesAsPaidModal.vue'
import MarkAsPaidConfirmationModal from '@/views/Partners/components/MarkAsPaidConfirmationModal.vue'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'

export default {
  name: 'PartnerInformation',
  components: {
    RouteDescription,
    MarkRevenuesAsPaidModal,
    MarkAsPaidConfirmationModal,
    BS_modal,
    DownloadButton
  },
  mixins: [TableView],
  inject: ['openDeductRevenue', 'refresh_key'],
  data () {
    return {
      partnerSid: '',
      downloadingReport: false,
      isResyncModal: false,
      selectedResyncVehicles: [],
      isResyncLoading: false,
      isResyncModalLoading: false,
      isResyncData: [],
      showMarkAsPaidConfirmationModal: false,
      selectedRevenueData: {},
      showMultipleRevenueModal: false,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      datePicker: null,
      id: parseInt(this.$route.params.payoutId),
      earningId: this.$route.params.earningId,
      loading: false,
      partner: null,
      partnerEarns: null,
      fetchPart: false,
      loadPayout: false,
      approver: null,
      loadingDeductions: false,
      fields: [
        {
          key: 'serial',
          label: 'S/N'
        },
        {
          key: 'date',
          label: 'Trip Date'
        },
        {
          key: 'timeOfCreation',
          label: 'Time of Creation'
        },
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'routeCode',
          label: 'Route code'
        },
        {
          key: 'amount',
          label: 'Amount earned(₦)'
        },
        {
          key: 'deduction',
          label: 'Deduction(₦)'
        },
        {
          key: 'netincome',
          label: 'Net income(₦)'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'isSettled',
          label: 'Status'
        },
        {
          key: 'action',
          label: 'action'
        }
      ],
      partnerItinerary: [],
      payoutRange: [],
      earningDeductions: []
    }
  },
  computed: {
    dateRange () {
      if (!this.datePicker) return []
      return this.datePicker.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid () {
      if (!this.datePicker) return []
      return (
        this.datePicker.length &&
        this.datePicker.filter((date) => !!date).length > 0
      )
    }
  },
  methods: {
      async  downloadReport() {
      this.downloadingReport = true
      this.perPage = 10000
      await this.getPartnerItinerary()
      const csvData = this.partnerItinerary.map((data) => {
        return {
          trip_date: moment(data.tripStartTime).format('Do, MMMM YYYY'),
          created_date: moment(data.createdAt).format('Do, MMMM YYYY'),
            pickup: data.metadata.pickup,
          dropoff: data.metadata.dropoff,
              routeCode: data.metadata.routeCode,
              amountEarned: data.partnersRevenue,
              deduction: data.totalDeductedAmount,
          netIncome: data.partnersRevenue - data.totalDeductedAmount,
          description: data.description,
              status: data.isSettled ? 'settled' : 'not settled',
        }
      })


      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length ? 'Payout List' : `Payout List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length ? 'Payout List' : `Payout List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Trip Date',
          'Time of Creation',
          'Route',
          'Route code',
          'Amount earned(₦)',
          'Deduction(₦)',
          'Net income(₦)',
          'Description',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)
      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    async fetchEarningDeductions() {
      try {
        this.loadingDeductions = true
        const res = await this.axios.get(`cost-revenue/v1/deductions?earningId=${this.earningId}`)
        this.earningDeductions = res.data.result
      } catch (e) {
        this.$toastr.e(e.response.data.message , 'Error')
      } finally{
        this.loadingDeductions = false

      }
    },
    async resyncVechicles () {
      const sent_data = {
        ...this.getMonthStartEndDates(this.partnerEarns?.referenceTime),
        partner_id: this.id,
        vehicle_ids: this.selectedResyncVehicles.map((vehicle) => vehicle.id)
      }
      try {
        this.isResyncLoading = true
        const res = await this.axios.post('/v1/trips/revenues', sent_data)
        this.isResyncLoading = false
        this.$toastr.s(
          `${res.data.message}`,
          'Success'
        )
        this.getPartnerDets()
        this.isResyncModal = false
      } catch (e) {
        this.isResyncLoading = false
        this.$toastr.e(e.response.data.message, 'Error')
      }
    },
    openResyncModal () {
      this.isResyncModal = true
      if (this.isResyncModal) this.fetchPartnerVehicles()
    },
    async fetchPartnerVehicles () {
      this.isResyncModalLoading = true
      const res = await this.axios.get(`/v1/partner/${this.id}/vehicles`, { related: 'driver' })
      this.isResyncModalLoading = false
      this.isResyncData = res.data.data
    },
    getMonthStartEndDates (dateString) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = date.getMonth()
      const startDate = new Date(year, month, 2).toISOString().split('T')[0]
      const endDate = new Date(year, month + 1, 1).toISOString().split('T')[0]
      return { startDate, endDate }
    },
    refreshTable () {
      this.getPartnerItinerary()
    },
    closeModalAndRefreshView () {
      this.closeMultipleRevenueModal()
      this.getPartnerItinerary()
      this.showMultipleRevenueModal = false
    },
    closeMultipleRevenueModal () {
      this.showMultipleRevenueModal = false
    },
    async markRevenuesAsPaid () {
      this.partnerSid = this.partner?.account_sid
      this.showMultipleRevenueModal = true
    },
    markRevenueAsPaid (selectedRevenueDetails) {
      this.selectedRevenueData = {
        partnerId: selectedRevenueDetails.partnerId,
        revenueId: selectedRevenueDetails.revenueId,
        earningId: this.earningId
      }
      this.showMarkAsPaidConfirmationModal = true
    },
    getTripDate (date) {
      return moment(date).format('LL')
    },
    getTripTime (date) {
      return moment(date).format('LT')
    },
    async getPartnerDets () {
      this.loading = true
      try {
        const res = await this.axios.get(`/v1/partners/${this.id}`)
        if (res.status == 200) {
          this.partner = res.data
        }
      } catch (err) {

      } finally {
        this.loading = false
        this.fetchApproval()
        this.getPartnerEarningsDets()
        this.getPartnerItinerary()
        this.fetchEarningDeductions()
      }
    },
    async getPartnerEarningsDets () {
      this.loadPayout = true
      try {
        const res = await this.axios.get(
          `cost-revenue/v1/earnings/${this.earningId}`
        )
        if (res.status == 200) {
          this.partnerEarns = res.data
        }
      } catch (err) {

      } finally {
        this.loadPayout = false
      }
    },
    async getPartnerItinerary (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchPart = true
      try {
        const uri = `cost-revenue/v1/partners/${this.partner.account_sid}/revenues`
        const queryParams = {
          earningId: this.earningId,
          page: this.currentPage,
          perPage: this.perPage
        }

        if (this.dateFilterValid) {
          queryParams.startDate = this.dateRange[0]
          queryParams.endDate = this.dateRange[1]
        }
        const res = await this.axios.get(uri, { params: queryParams })
        const r = res.data.result
        r.forEach((item) => {
          const e = new Date(item?.metadata?.startTime)
          item.date = e?.toDateString()
          item.time = e?.toLocaleTimeString()
        })
        this.partnerItinerary = r
        this.totalRecords = res.data?.metadata?.total
        // this.partnerEarns.netRevenue =
        //   res.data.metadata?.revenue?.netRevenue || 0
        // this.partnerEarns.totalDeductions =
        //   res.data.metadata?.revenue?.totalDeductions || 0
        // this.partnerEarns.totalRevenue =
        //   res.data.metadata?.revenue?.totalRevenue || 0
        return this.partnerItinerary
      } catch (err) {
        this.$toastr.e(err?.response?.data?.message, 'Error')
      } finally {
        this.fetchPart = false
      }
    },
    async fetchApproval () {
      this.loadPayout = true
      try {
        const res = await this.axios.get(
          `cost-revenue/v1/earnings/${this.earningId}/approvers`
        )
        if (res.status == 200) {
          if(res.data.length) this.approver = res.data
        }
      } catch (err) {

      } finally {
        this.loadPayout = false
      }
    }
  },
  created () {
    this.getPartnerDets()
  },
  watch: {
    currentPage () {
      this.getPartnerItinerary()
    },
    search (value) {
      if (!value) {
        this.getPartnerItinerary()
      }
    },
    datePicker: debounce(function () {
      this.getPartnerItinerary()
    }, 100),
    refresh_key() {
      this.getPartnerDets()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/tooltip';

#settled-tag {
  // font-size: 8px;
}

.pay-nav {
  color: #b9bcc8;
}

.pat-head-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.284358px;
  color: #6e717c;
}

.pat-sub-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: -0.284358px;
  color: #000005;
}

.pay-head-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595e5c;
}

.pay-amount {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  color: #000005;
}

.pat-link {
  color: #4848ed;
  text-decoration: underline;
}
</style>
